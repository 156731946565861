<template>
  <div class="content">
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col col-sm">
          <div class="col-lg-12 col-xl-12" style="padding: 0px;">
            <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'Contatos'" :subtitle="'Detalhes'" />
          </div>
          <div class="col-lg-12 col-xl-12 info-header">
            <h4>Detalhes de contato</h4>
            <p>
              Tenha todas as informações de contato essenciais em um só lugar: fácil, rápido e conveniente. Simplifique sua vida com detalhes de contato organizados.
            </p>
          </div>
          <div class="col-12">
            <div class="card card-tabs">
              <div class="card-header">
                <ul class="nav nav-tabs">
                  <li class="nav-item c-pointer" role="presentation" @click="tabLink = 'info'">
                    <a class="nav-link" :class="{ 'router-link-exact-active router-link-active': tabLink == 'info' }">
                      <span class="material-symbols-outlined"> playlist_add </span> Informações gerais
                    </a>
                  </li>
                  <li class="nav-item c-pointer" role="presentation" @click="tabLink = 'social'">
                    <a class="nav-link" :class="{ 'router-link-exact-active router-link-active': tabLink == 'social' }">
                      <span class="material-symbols-outlined"> share </span> Social
                    </a>
                  </li>
                  <li class="nav-item c-pointer" role="presentation" @click="tabLink = 'address'">
                    <a class="nav-link" :class="{ 'router-link-exact-active router-link-active': tabLink == 'address' }">
                      <span class="material-symbols-outlined"> home </span> Endereço
                    </a>
                  </li>
                  <li class="nav-item c-pointer" role="presentation" @click="tabLink = 'history'">
                    <a class="nav-link" :class="{ 'router-link-exact-active router-link-active': tabLink == 'history' }">
                      <span class="material-symbols-outlined"> history </span> Histórico
                    </a>
                  </li>
                  <li class="nav-item c-pointer" role="presentation" @click="tabLink = 'support'">
                    <a class="nav-link" :class="{ 'router-link-exact-active router-link-active': tabLink == 'support' }">
                      <span class="material-symbols-outlined"> headset </span> Atendimento
                    </a>
                  </li>
                  <div class="opts_card">
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-12" v-if="tabLink == 'info'">
            <div class="row">
              <!-- Content col -->
              <div class="col-8">
                <div class="form-group mb-4">
                  <label for="wpp-name">Nome*</label>
                  <input id="wpp-name" class="form-control" :placeholder="'Primeiro Nome'" v-model="contact.first_name">
                </div>
                <div class="form-group mb-4">
                  <label for="wpp-name">Sobrenome*</label>
                  <input id="wpp-name" class="form-control" :placeholder="'Sobrenome'" v-model="contact.last_name">
                </div>
              </div>
              <div class="col-4">
                <div class="profile-picture text-center">
                  <div class="profile-image">
                    <div class="profile-photo">
                      <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }" v-if="contact.profile_picture == null">
                        <div class="fallback-image">
                          {{initials(contact.first_name, contact.last_name)}}
                        </div>
                      </figure>
                    </div>
                    <div id="img-preview" :class="{ hide: contact.profile_picture == null }" :style="`background: url(${contact.profile_picture})`"></div>
                  </div>
                  <div class="c-pointer btn-change-picture" @click="
                      callCrop(
                        'img-preview',
                        512,
                        512,
                      )
                    ">
                    <div>
                      <span class="material-symbols-outlined"> file_upload </span>
                    </div>
                    <span>Alterar avatar</span>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <!-- Body -->
                <div class="form-group mb-4">
                  <label>Descrição: </label>
                  <textarea class="form-control" placeholder="Crie uma breve descrição de sua empresa" rows="5" v-model="contact.description"></textarea>
                  <small class="form-text text-muted text-right">Não exceda 200 caracteres ao inserir a descrição</small>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-12">
                  <label>Empresa</label>
                  <input class="form-control" v-model="contact.company">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mb-4">
                  <label>Gênero</label>
                  <select-input
                    label="text"
                    :reduce="(gender) => gender.value"
                    :options="genders"
                    :clearable="false"
                    v-model="contact.gender"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mb-4">
                  <label>Data de Nascimento</label>
                  <input
                    class="form-control"
                    v-model="contact.birthdate"
                    placeholder=""
                    type="text">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-4">
                  <label>Email</label>
                  <input class="form-control" v-model="contact.email">
                </div>
              </div>
              <div class="col-12">
                <!--/Body -->
                <!-- Footer -->
                <div class="form-group mb-4">
                  <label for="wpp-footer">Telefone</label>
                  <tel-input ref="phone_number" v-model="contact.mobile_number" :value="contact.mobile_number"/>
                </div>
              </div>
              <!--/Content col -->
            </div>
          </div>
          <div class="col-lg-12 col-xl-12" v-if="tabLink == 'social'">
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-4">
                  <label>Site</label>
                  <input class="form-control" placeholder="Ex.: https://meudominio.com" v-model="contact.website">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-4">
                  <label>https://twitter.com/</label>
                  <input class="form-control" placeholder="Nick name" v-model="contact.twitter">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-4">
                  <label>https://facebook.com/</label>
                  <input class="form-control" placeholder="Nick name" v-model="contact.facebook">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-4">
                  <label>https://linkedin.com/</label>
                  <input class="form-control" placeholder="Nick name" v-model="contact.linkedin">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-4">
                  <label>https://instagram.com/</label>
                  <input class="form-control" placeholder="Nick name" v-model="contact.instagram">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-12" v-if="tabLink == 'address'">
            <div class="row">
              <!-- Content col -->
              <div class="col-6">
                <div class="form-group mb-4">
                  <label for="wpp-name">CEP</label>
                  <input id="wpp-name" class="form-control" :placeholder="'Primeiro Nome'" maxlength="512" v-model="contact.zip">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mb-4">
                  <label for="wpp-name">Estado</label>
                  <select v-model="contact.state" type="text" class="form-control">
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="EX">Estrangeiro</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-4">
                  <label>Cidade: </label>
                  <input class="form-control" v-model="contact.city">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-12">
                  <label>Endereço</label>
                  <input class="form-control" v-model="contact.address">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-12">
                  <label>Bairro</label>
                  <input class="form-control" v-model="contact.district">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-12">
                  <label>Número</label>
                  <input class="form-control" v-model="contact.number">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mb-12">
                  <label>Complemento</label>
                  <input class="form-control" v-model="contact.complement">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-12" v-if="tabLink == 'history'">
            <div class="row comments-row">
              <div class="col">
                <ol class="timeline">
                  <div>
                    <ul class="list-notify-dates l-style-none">
                      <li>
                        <ul class="l-style-none">
                          <li class="timeline-item">
                            <span class="timeline-item-icon filled-icon type-alert">
                                <span class="material-symbols-outlined">notifications</span>
                            </span>
                            <div class="timeline-item-wrapper">
                              <div class="timeline-item-description">
                                <span class="commented-at">
                                  Novo lembrete cadastrado
                                </span>
                                <span class="commented-at-date">
                                  Hoje às 12:30
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-12 hide">
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-4">
                  <hr>
                </div>
                <!--/Footer -->
                <div class="btn-wrapper">
                  <div>
                    <div class="link-help">
                      <span class="material-symbols-outlined info-icon">help</span>
                      <a href="https://developers.facebook.com/docs/whatsapp/cloud-api/get-started/migrate-existing-whatsapp-number-to-a-business-account">Suporte</a>
                    </div>
                  </div>
                  <div class="text-right">
                    <button class="btn btn-outline-primary" @click="$router.go(-1)" style="margin-right: 10px;">{{ $t('app.cancel') }}</button>
                    <button class="btn btn-yup-purple ml-auto">
                      Salvar
                    </button>
                  </div>
                </div>
                <div class="form-group mb-4">
                  <br><br>
                </div>
              </div>
              <!--/Content col -->
            </div>
          </div>
        </div>
        <div class="col-3 side-column">
          <div>
            <h3 class="side-title">Nogociações</h3>
          </div>
          <div  v-for="(deal, i) in deals" :key="i">
            <div class="step-deal">
              <div class="step-deal-head">
                <span class="step-title">{{deal.title}}</span>
                <div class="step-title-right">
                  <span class="material-symbols-outlined"> schedule </span>
                  <span><time :datetime="deal.due_date">{{deal.due_date | displayDate(months, today, $i18n.locale)}}</time></span>
                </div>
              </div>
              <div class="step-footer">
                <div class="step-footer-left">
                  <span v-if="deal.priority" :class="`badge badge-${deal.priority}`">
                    ● {{$t(`workflow.workspace.status.${deal.priority}`)}}
                  </span>
                </div>
                <div class="step-footer-right">
                  <span class="material-symbols-outlined"> monetization_on </span>
                  <span>{{deal.offering_values ? deal.offering_values.single  : 0 | currency}}</span>
                  <span class="material-symbols-outlined hide"> notification_important </span>
                  <span class="hide">3</span>
                  <span class="material-symbols-outlined"> shopping_cart </span>
                  <span>+{{deal.offerings.length}}</span>
                </div>
              </div>
            </div>
            <div class="deal-negotiation">
              <ul class="ul-deal-step">
                <li v-for="(task, i) in deal.tasks" :key="i" class="m-top-15">
                  <div class="card card-task">
                    <div class="card-header">
                      <div class="task-title">{{task.title}}</div>
                      <div class="options-task">
                        <ul class="list-unstyled">
                          <li>
                            <small>
                              <span class="material-symbols-outlined">schedule</span>
                              <time :datetime="task.due_date">{{task.due_date | displayDate(months, today, $i18n.locale)}}</time>
                            </small>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="task-description">
                        {{task.description}}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="deals.length == 0" class="no-item">
            <span class="material-icons-outlined">filter_alt</span>
          </div>
        </div>
      </div>
    </div>
    <m-crop id="m-crop" />
  </div>
</template>

<script>
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Tab from '@/directives/Tab';
import TelInput from '@/components/form/TelInput.vue';
import SelectInput from '@/components/SelectInput.vue';
import MCrop from '@/components/MCrop.vue';
import ContactService from '@/services/contact.service';
import axios from '@/plugins/axios';
import BucketService from '@/services/bucket.service';

const webApi = axios();

export default {
  name: 'WhatsappDashboard',
  components: {
    PageHeader,
    TelInput,
    SelectInput,
    MCrop,
  },
  directives: {
    Tab,
  },
  filters: {
    displayDate(date, monthNames, localeToday, locale) {
      const today = new Date();
      const current = new Date(date);

      if (today.toDateString() === current.toDateString()) {
        return localeToday;
      }

      const months = monthNames;
      const y = current.getFullYear();
      const m = current.getMonth();
      const d = `${current.getDate()}`.padStart(2, 0);

      return (locale === 'en' ? `${months[m]} ${d}, ${y}` : `${d} ${months[m]}, ${y}`);
    },
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/contacts',
        2: '/contacts/detail',
      },
      tabLink: 'info',
      file: {},
      months: this.$t('primevue.monthNames'),
      today: this.$t('primevue.today'),
      contact: {},
      genders: [
        {
          text: 'Feminino',
          value: 'female',
        },
        {
          text: 'Masculino',
          value: 'male',
        },
        {
          text: 'Prefiro não dizer',
          value: 'notsay',
        },
      ],
      cropPreview: {},
      cropActive: {},
      deals: [],
    };
  },
  beforeDestroy() {
    this.$root.$off('call.crop', () => { });
  },
  created() {
    this.contact = this.$route.params;
    this.$root.$once('call.crop', this.crop);
    webApi.post(`/pipelines/deals/by-contact/${this.contact.id}`).then((response) => {
      console.log('Deals');
      console.log(response);
      this.deals = response.data.data;
    }, (error) => {
    });
  },
  // Pai
  methods: {
    initials(firstname, lastname) {
      (firstname === null ? firstname = '' : firstname); // Avoiding displaying 'null' string
      (lastname === null ? lastname = '' : lastname); // Avoiding displaying 'null' string
      const emojiRegex = /\p{Emoji}/u;
      // console.log('Firstname: ', firstname, '\nLastname: ', lastname);
      let fullname = '';
      if (firstname) {
        firstname = !firstname.match(emojiRegex) ? firstname.charAt(0).toUpperCase() : firstname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      if (lastname) {
        lastname = !lastname.match(emojiRegex) ? lastname.charAt(0).toUpperCase() : lastname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      fullname = firstname + lastname;
      return fullname;
    },
    callCrop(
      cropPreview,
      maxWidthP,
      maxHeightP,
    ) {
      this.cropPreview = cropPreview;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    crop(data) {
      if (data) {
        this.file = data;
        this.contact.profile_picture = this.file.url;
        document.getElementById(this.cropPreview).style = `background: url(${
          this.file.url
        })`;

        const formData = new FormData();
        const filesCrop = [];
        filesCrop.push({
          x: this.file.x,
          y: this.file.y,
          w: 512,
          h: 512,
        });
        formData.append('files[]', this.file.file);
        formData.append('role', 'public');
        formData.append('folder', 'contacts');
        formData.append('crop', JSON.stringify(filesCrop));

        BucketService.upload(
          formData,
          true,
          () => {},
        )
          .then(
            (responses) => {
              console.log(responses);
              console.log(responses.data.url);
              ContactService.editImage({
                id: this.contact.id,
                url: responses.data[0].url,
              }).then(
                () => {
                  this.$toast.show({
                    title: this.$t('generic-str.success'),
                    content: this.$t('contact-component.update'),
                    type: 'success',
                  });
                },
                (error) => {
                  this.$toast.show({
                    title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                    content: error.message,
                    type: 'danger',
                  });
                  this.isSending = false;
                },
              );
            },
            (error) => {
              this.content = error;
              this.isSending = false;
              console.log(error);
            },
          )
          .finally(() => {
            this.isSending = false;
          });
      }
      console.log(this.file);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

.card {
  box-shadow: none !important;
}

.nav-tabs {
  border-bottom: 4px solid #6B23DC3D;
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  border-bottom: solid 4px var(--clr-yup-purple);
}

.nav-tabs .nav-item {
  margin-bottom: -4px;
}

::v-deep .icon-calendar {
  color: var(--gray-font-color) !important;
}

.btn-add span {
  color: #6B23DC;
  font-size: 16px !important;
}

table {
  width: 100%;
  border-spacing: 0 12px;
  border-collapse: separate;
  margin: -24px 0;
  table-layout: fixed;

  thead {
    th {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      // border-bottom: color-mix(in srgb, var(--clr-yup-purple) 20%, transparent) 4px solid;
      border-bottom: none;
    }
  }

  tbody {
    tr {

      td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid #E5E5E5;
        padding: 16px;

        .material-symbols-outlined {
          vertical-align: text-bottom;
        }
      }

      td:first-child {
        border-left: 1px solid #E5E5E5;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-right: 1px solid #E5E5E5;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}

.icon-red {
  color: #AD1519;
}

.info-header h4 {
  font-size: 20px;
  color: #101828;
  font-weight: 400;
}

.info-header p {
  color: #595959;
  font-weight: 400;
}
.info-alert {
  background: #F1F7FD;
  font-size: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
  flex-direction: row;
  display: flex;
}
.info-alert .info-icon {
  color: #0070E4;
  margin-right: 10px;
  margin-top: 5px;
}
.link-help {
  display: flex;
  flex-direction: row;
}
.link-help span{
  font-size: 16px;
  margin-top: 2px
}
.link-help a {
  font-size: 14px;
  margin-left: 5px;
  color: #595959;
  text-decoration: underline;
}
.btn-wrapper {
  display: flex;
  flex-direction: row;
}
.btn-wrapper div {
  width: 50%;
}
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}
.card.card-tabs .card-header .nav.nav-tabs{
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs {
  margin-bottom: 5px;
}
.nav-tabs{
  border-bottom: 4px solid #6B23DC3D;
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active{
  border-bottom: solid 4px var(--clr-yup-purple);
}
.nav-tabs .nav-item{
  margin-bottom: -4px;
}

/*Notification*/
.comments-row {
  padding-left: 48px;
}
.comments-row .timeline {
  width: 100%;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 32px 0 32px 32px;
  border-left: 3px solid #6B23DC;
  font-size: 1.125rem;
}
.comments-row .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
.comments-row .timeline-item + * {
    margin-top: 24px;
}
.comments-row .timeline-item-icon.filled-icon {
  color: #fff;
}

.comments-row .timeline-item-icon.filled-icon.type-text {
  background-color: #688afd;
}

.comments-row .timeline-item-icon.filled-icon.type-warning {
  background-color: #f3b033;
}

.comments-row .timeline-item-icon.filled-icon.type-info {
  background-color: #9597d9;
}

.comments-row .timeline-item-icon.filled-icon.type-alert {
  background-color: #f4eefe;
}
.type-alert span{
  color: #6B23DC;
}
.comments-row .timeline-item-icon.filled-icon.type-success {
  background-color: #32b757;
}

.comments-row .user-name-picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #fff !important;
  border-radius: 50%;
  font-weight: 400;
  font-size: inherit;
  font-style: normal;
}
.comments-row .timeline-item-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-left: -132px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 6px #fff;
  box-shadow: 0 0 0 6px #fff;
  pointer-events: none;
}
.comments-row .timeline-item-icon svg {
  width: 20px;
  height: 20px;
}
.comments-row .timeline-item-description {
  padding-top: 6px;
  gap: 8px;
  color: var(--c-grey-400);
}
.comments-row .user-name-picture.small {
    width: 32px;
    height: 32px;
}
.comments-row .commented-at {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    float: left;
}
.comments-row .timeline-item-description a {
    color: var(--c-grey-500);
    font-weight: 500;
    text-decoration: none;
}
.comments-row .comment {
    position: relative;
    margin-top: 12px;
    color: var(--c-grey-500);
    border: 1px solid var(--c-grey-200);
    -webkit-box-shadow: 0 4px 4px 0 var(--c-grey-100);
    box-shadow: 0 4px 4px 0 var(--c-grey-100);
    border-radius: 6px;
    padding: 16px;
    font-size: 1rem;
}
.comments-row .comment p {
    margin-bottom: 0;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
button .delete-icon {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    color: var(--gray-font-color-3);
    margin-left: auto;
    font-size: 18px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
}
.icon-date-notify {
  margin-top: 30px !important;
}
.list-notify-dates > li:first-child .icon-date-notify{
  margin-top: -30px !important;
}
.icon-date-notify {
  margin-left: -120px !important;
  margin-bottom: 50px !important;
  border-radius: 5% !important;
  background-color: #752de6 !important;
  height: auto !important;
  width: 110px !important;
  padding: 5px !important;
}
.li::marker {
  display: none !important;
}
.block {
  display: block !important;
}
.no-content {
  font-size: 25px;
  color: #ccc;
  margin-top: 30px;
  text-align: center;
}
.no-content span {
  display: block;
  font-size: 70px;
}
.l-style-none {
  list-style: none !important;
}
.timeline-item-wrapper {
  width: 100%;
}
.commented-at-date {
  float: right;
  color: #585858;
  font-weight: 400;
  font-size: 10px;
}
.side-column {
  padding-top: 30px;
  background-color: var(--gray-font-color-7);
}
.side-title {
  font-size: 20px;
  font-weight: 400;
  color: #101828;
}
.step-deal {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 2px 8px 4px 8px;
  border-bottom: solid 4px #e2e2e2;
  border-bottom: solid 2px #0000001A;
  box-shadow: 0px 3px 6px #0000001A;
}
.rebranding .badge.badge-high {
    background-color: var(--high-bg) !important;
    border-color: var(--high-fg) !important;
    color: var(--high-fg) !important;
}
.badge {
    font-weight: 400;
    vertical-align: middle;
    text-align: center;
    line-height: 1.5;
    padding: 4px 6px 2px;
}
.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.step-title {
  font-size: 12px;
  font-weight: 400;
  color: #595959;
  width: 80%;
}
.step-deal-head {
  display: flex;
  flex-direction: row;
}
.step-title-right {
  font-size: 8px;
  font-weight: 400;
  color: #595959;
  width: 20%;
}
.step-title-right .material-symbols-outlined {
  font-size: 8px;
  margin-right: 3px;
}
.step-footer-right span{
  font-size: 10px;
  color: #807C8D;
  font-weight: 400;
}
.step-footer {
  display: flex;
}
.step-footer-left {
  width: 50%;
}
.step-footer-right {
  width: 50%;
}
.step-footer-right .material-symbols-outlined{
  margin-left: 3px;
  margin-right: 2px;
}
.card-task {
  border-radius: 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: auto;
  border-left: 5px solid var(--clr-yup-purple);
}

.card-task .card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: .5rem;
    padding-left: .25rem;
}
.rebranding .card .card-header, .rebranding .card .card-header .header_title {
    border-bottom-color: transparent;
    font-weight: 400;
}
.rebranding .card .card-header {
    min-height: unset !important;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.card .card-header {
    position: relative;
    border-bottom: 1px solid rgba(234, 243, 253, .9);
    background-color: transparent;
    min-height: 45px;
}
.card .card-header, .card .card-header .card-title {
    font-weight: 300;
    font-size: 1rem;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.card-task {
  box-shadow: 0px 3px 6px #0000001A !important;
}
.card-task .card-header .options-task {
  margin-left: auto;
}
.card-task .card-header .options-task ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    gap: 5px;
    margin-bottom: 0;
}
.card-task .card-header .options-task small{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--gray-500);
}
.card-task .card-header .options-task small .material-symbols-outlined{
    margin-right: .25rem;
    font-size: .85rem;
}
.card-task .card-body {
    padding: .5rem;
    padding-left: .25rem;
    padding-top: 0;
    padding-bottom: 0;
    -ms-flex-preferred-size: unset;
    flex-basis: unset;
    -webkit-box-flex: initial;
    -ms-flex-positive: initial;
    flex-grow: initial;
}
.card-task .card-body .task-description .card-task .card-body .toggle-custom {
    font-size: .75rem;
}
.ul-deal-step {
  border-left: 1px solid #DBDBDB;
  list-style: none;
  margin-left: 20px;
  padding: 15px 0px 0px 20px;
}
.profile-image #img-preview {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 3px var(--clr-yup-purple);
  background-position: top !important;
  background-size: cover !important;
  margin: 0 auto;
}
.btn-change-picture {
  font-size: 12px;
  color: #6F6F6F;
  margin-top: 10px
}
.profile-picture {
  border: dotted 1px #E4E7EC;
  border-radius: 8px;
  padding: 15px 45px 15px 45px;
}
.profile-photo {
  width: 115px;
  margin: 0 auto;

  figure {
    margin: 0 auto;
    border-radius: 48px;
    background: #752de6;
    width: 92px;
    height: 92px;
  }

  &:hover {
    color: #fc0;

    & ~ .profile-more-details {
      opacity: 1;
      z-index: 100;
    }
  }
}
.fallback-image {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 2.2rem;
  color: #fff;
}
.m-top-15 {
  margin-top: 15px !important;
}
.no-item {
  text-align: center;
  font-size: 30px;
  color: #ccc;
  margin-top: 40px;
}
</style>
